import(/* webpackMode: "eager", webpackExports: ["AutoComplete","Empty"] */ "__barrel_optimize__?names=AutoComplete,Empty!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Checkbox"] */ "__barrel_optimize__?names=Checkbox!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "__barrel_optimize__?names=Input!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["InputNumber"] */ "__barrel_optimize__?names=InputNumber!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "__barrel_optimize__?names=Modal!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/home/_sections/facturacion.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/home/_sections/garantia.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/home/_sections/hombre.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/home/_sections/privacidad.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/home/_sections/suscripciones.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Buttons/Button.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Buttons/CustomButton.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Forms/Form.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Icon/Icon.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Inputs/Dragger.jsx");
